import { getIsOnpremise } from "../../../utils";
import { replaceHostName } from "../../../utils/replaceHostName";
import servicesUtils from "../../../utils/servicesUtils";
import Store from "../../../utils/Store";
function patientsExportService(url, params) {
  return servicesUtils.postService(url, params);
}
var downloadFile = function downloadFile() {
  var isOnpremise = getIsOnpremise();
  var url = "patients_export/get_download_link/";
  patientsExportService(url).then(function (response) {
    var _response$data;
    var link = document.createElement("a");
    var csv_link = (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.csv_link;
    if (isOnpremise) {
      csv_link = replaceHostName(csv_link);
    }
    link.href = csv_link;
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    Store.update(function (s) {
      s.showExportUpdate = false;
    });
  })["catch"](function (resp) {
    Store.update(function (s) {
      s.showExportUpdate = true;
      s.exportGenFailed = true;
    });
  });
};
export { downloadFile, patientsExportService };