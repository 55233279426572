import _defineProperty from "/vercel/path1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import servicesUtils from "../../../utils/servicesUtils";
var getEmergencyPatients = function getEmergencyPatients() {
  var apiParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return servicesUtils.postService("/patients/", _objectSpread({
    emergency: true,
    page_size: 30
  }, apiParams));
};
var postWorklistTagConfig = function postWorklistTagConfig(params) {
  return servicesUtils.postService("profile/worklist_tags_config/", {
    worklist_tags_config: params
  });
};
export { getEmergencyPatients, postWorklistTagConfig };