import classNames from "classnames";
import useMedia, { MediaSizes } from "../../hooks/useMedia";
import { useTranslation } from "react-i18next";
import { useStoreState } from "pullstate";
import Store from "../../utils/Store";
import { get, isEmpty } from "lodash";

const Tag = ({
  label,
  onClick,
  isSelected = false,
  disabled = false,
  mapping = false,
}) => {
  const isMobile = useMedia([MediaSizes.MOBILE], [true], false);
  const { t } = useTranslation();
  const { userInfo } = useStoreState(Store, (s) => ({
    userInfo: s.userInfo,
  }));
  const uiSettings = get(userInfo, ["settings", "ui_settings"], {});

  const getLabelTranslation = () => {
    if (mapping && uiSettings?.critical_filters_logic === "others") {
      if (label === "positive" || label === "negative") {
        return t(`mapping.others.${label}`);
      }
    }
    return mapping ? t(`mapping.${label}`) : label;
  };

  return (
    <button
      disabled={disabled}
      className={classNames(
        "rounded-full border border-gray-600 w-max px-5 cursor-pointer sm:text-[12px] sm:py-2",
        {
          "!bg-teal-400 !text-gray-100 !border-teal-400": isSelected,
          "hover:border-teal-400 hover:bg-teal-400 hover:text-gray-100":
            !isMobile && !disabled,
          "opacity-50 !cursor-not-allowed": disabled,
        }
      )}
      onClick={onClick}
    >
      {getLabelTranslation()}
    </button>
  );
};

export default Tag;
